import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
    return request.post(constant.serverUrl + "/base/goodsInfo/pageList", formData);
}

function create() {
    return request.get(constant.serverUrl + "/base/goodsInfo/create");
}

function edit(id) {
    return request.get(constant.serverUrl + "/base/goodsInfo/edit/" + id);
}

function add(formModel) {
    return request.post(constant.serverUrl + "/base/goodsInfo/add", formModel, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function update(formModel) {
    return request.post(constant.serverUrl + "/base/goodsInfo/update", formModel, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function remove(id) {
    return request.post(constant.serverUrl + "/base/goodsInfo/delete/" + id);
}

function batchRemove(idList) {
    return request.post(constant.serverUrl + "/base/goodsInfo/batchDelete", idList, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}


function typeList(formData) {
    return request.post(constant.serverUrl + "/base/goodsInfo/typeList", formData, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function findByGoodsId(formData) {
    return request.post(constant.serverUrl + "/base/goodsInfo/findByGoodsId", formData);
}

function goodsTypeList(formData) {
    return request.post(constant.serverUrl + "/base/goodsInfo/goodsTypeList", formData);
}

export default {
    pageList, create, edit, add, update, remove, batchRemove, typeList, findByGoodsId,goodsTypeList
}
